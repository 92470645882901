import { ArrowBack, Edit } from '@material-ui/icons'
import { Button, Grid, IconButton, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import Locale from 'date-fns/locale/en-GB'
import moment from 'moment'
import { useEffect, useState } from 'react'
import 'react-datetime/css/react-datetime.css'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContentWrapper from '../Layout/ContentWrapper'

const resign_type = {
  voluntary: 'Vullnetare',
  involuntary: 'Jo-Vullnetare'
}

const ResignDetails = () => {
  const { resignId } = useParams()
  const { goBack } = useHistory()
  const [resignDetails, setResignDetails] = useState([])
  const [newValue, setNewValue] = useState({ key: null, value: null })

  const transformData = (item) => [
    { label: 'Emri & Mbiemri', key: 'name', value: `${item?.user_id?.first_name} ${item?.user_id?.last_name}` },
    { label: 'Email', key: 'email', value: item?.user_id?.email },
    { label: 'Lloji i dorëheqjes', key: 'resign_type', value: resign_type[item?.resign_type] },
    { label: 'Arsyeja', key: 'resign_reason', value: item?.resign_reason, editable: true },
    { label: 'Data e krijimit të dorëheqjes', key: 'created_date', value: moment(item?.created_date).format('DD/MM/YYYY') },
    {
      label: 'Data e dorëheqjes',
      key: 'resign_day',
      value: moment(item?.resign_day).format('DD/MM/YYYY'),
      editable: true,
      type: 'date'
    },
    {
      label: 'Data e fundit në punë',
      key: 'last_date_on_work',
      value: item?.last_date_on_work ? moment(item?.last_date_on_work).format('DD/MM/YYYY') : '',
      editable: true,
      type: 'date'
    },
    {
      label: 'Ditët e mbetura të pushimit',
      key: 'leave_days',
      value: item?.leave_days,
      editable: true,
      type: 'number'
    },
    { label: 'Komenti për ditet e mbetura të pushimit', key: 'leaves_description', value: item?.leaves_description, editable: true },
    {
      label: 'Ditët e mbetura të punës',
      key: 'remaining_working_days',
      value: item?.remaining_working_days,
      editable: true,
      type: 'number'
    },
    {
      label: 'Komenti për ditët e mbetura të punës',
      key: 'remaining_working_days_description',
      value: item?.remaining_working_days_description,
      editable: true
    },
    { label: 'Komenti', key: 'comment', value: item?.comment, editable: true }
  ]

  const getData = async () => {
    try {
      const { data } = await axios.get(`resigns/resign/${resignId}`)
      if (data.success) {
        const transformedData = transformData(data.data)
        setResignDetails(transformedData)
      }
    } catch (error) {
      toast.error('Ndodhi një gabim!')
      console.log('error', error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const updateResignData = async () => {
    try {
      const { data } = await axios.put(`resigns/resign/${resignId}`, { [newValue.key]: newValue.value })
      if (data.success) {
        const transformedData = transformData(data.data)
        setResignDetails(transformedData)
        setNewValue({ key: null, value: null })
        toast.success('Detajet u ndryshuan me sukses!')
      }
    } catch (error) {
      toast.error('Ndodhi një gabim!')
      console.log('error', error)
    }
  }

  return (
    <ContentWrapper>
      <div className='d-flex justify-content-between'>
        <p style={{ fontSize: 23, color: 'gray' }}>Detajet e dorëheqjes</p>
        <Button variant='contained' size='small' sx={{ height: '30px' }} onClick={goBack} startIcon={<ArrowBack />}>
          Prapa
        </Button>
      </div>
      <div className='card card-default p-4'>
        <table style={{ tableLayout: 'fixed' }} className='table'>
          <tbody>
            {resignDetails?.map((item) => (
              <tr key={item?.key}>
                <td>
                  <strong>{item?.label}</strong>
                </td>
                <td>
                  {item?.key === newValue?.key ? (
                    <Grid container gap='10px' alignItems='center' sx={{ fieldset: { border: '1px solid' } }}>
                      {item?.type === 'number' ? (
                        <TextField
                          size='small'
                          type='number'
                          onChange={(e) => setNewValue({ key: item?.key, value: e.target.value })}
                          InputProps={{
                            inputProps: {
                              min: 0
                            }
                          }}
                        />
                      ) : item?.type === 'date' ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
                          <DatePicker
                            label='Data'
                            inputFormat='dd/MM/yyyy'
                            value={newValue.value}
                            onChange={(e) => {
                              console.log('e', e)
                              setNewValue({ key: item?.key, value: new Date(e) })
                            }}
                            renderInput={(params) => <TextField {...params} size='small' />}
                          />
                        </LocalizationProvider>
                      ) : (
                        <TextField
                          placeholder={item?.label}
                          size='small'
                          multiline={true}
                          onChange={(e) => setNewValue({ key: item?.key, value: e.target.value })}
                        />
                      )}
                      <Button variant='contained' size='small' disabled={!newValue?.value} onClick={updateResignData}>
                        Ruaj
                      </Button>
                      <Button variant='contained' color='error' size='small' onClick={() => setNewValue({ key: null, value: null })}>
                        Mbrapa
                      </Button>
                    </Grid>
                  ) : (
                    <Grid container gap='20px'>
                      <span style={{ width: '200px' }}>{item?.value}</span>
                      <span style={{ fontSize: 'small' }}>
                        {item?.editable && (
                          <IconButton size='small' onClick={() => setNewValue(() => ({ key: item?.key, value: null }))}>
                            <Edit fontSize='small' />
                          </IconButton>
                        )}
                      </span>
                    </Grid>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ContentWrapper>
  )
}

export default ResignDetails
