import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { inject } from 'mobx-react'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import pubsub from 'pubsub-js'
import React from 'react'
import { Line as LineChart } from 'react-chartjs-2'
import 'react-datetime/css/react-datetime.css'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, FormGroup, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Swal from 'sweetalert2'
import ContentWrapper from '../Layout/ContentWrapper'
import ImportTab from './ImportTab'
import { albanianMonths } from './enums/AlbanianMonths'
import './manageWages.scss'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'

var timeout = null // timeout for searchBox

@inject('userStore')
class ManageWages extends React.Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      showEmail: false,
      isLoading: false,
      searchText: '',
      activeTab: 'myWages',
      files: [],
      hasCSV: true,
      selectedDate: new Date(),
      total_base_salary: [],
      total_salary_net: [],
      total_bruto_salary: [],
      total_incentive: [],
      total_compensation: [],
      total_provision: [],
      total_tax: [],
      total_trust_employer: [],
      total_trust_employee: [],
      total_bonus: [],
      allExport: false,
      count: 10,
      total: 0,
      page: 1,
      getData: [],
      monthType: 1,
      yearType: 1,
      baseSalaryType: 1,
      incentiveType: 1,
      compensationType: 1,
      provisiontype: 1,
      brutoSalaryType: 1,
      salaryNetType: 1,
      trustType: 1,
      taxType: 1,
      createdDateType: 1,
      userFirstNameType: 1,
      userEmailType: 1,
      projectType: 1,
      functionType: 1,
      activityType: 1,
      sortParam: '',
      sortType: '',
      showDepartment: false,
      showProject: false,
      showActivity: false,
      showFunction: false,
      showLanguages: false,
      departmentRender: '',
      departmentSelected: '',
      projectRender: '',
      projectSelected: '',
      activityRender: '',
      activitySelected: '',
      functionRender: '',
      functionSelected: '',
      languageRender: '',
      languageSelected: '',
      projects: [],
      departments: [],
      activities: [],
      funksionet: [],
      languages: [],
      innerWidth: window.innerWidth
    }

    this._dateFunctionFiltered = this._dateFunctionFiltered.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this._getExport = this._getExport.bind(this)
    this._getDataForTable = this._getDataForTable.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions() {
    this.setState({ innerWidth: window.innerWidth })
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  _allExport = async (event) => {
    await this.setState({
      allExport: !this.state.allExport
    })
  }

  handleUpload = (event) => {
    this.setState({ files: event.target.files })
    if (event.target.files[0].type == 'application/vnd.ms-excel') {
      this.setState({
        hasCSV: true
      })
    } else {
      this.setState({
        hasCSV: false
      })
    }
  }

  async _dateFunctionFiltered() {
    const year = parseInt(moment(this.state.selectedDate).format('YYYY'))

    try {
      const res = await axios.get(`salaries/salariesstats/`, {
        params: {
          year: parseInt(year)
        }
      })
      if (res.data.success) {
        if (this._isMounted) {
          if (res.data.data.length > 0) {
            let total_base_salary = Array(12).fill(0)
            let total_salary_net = Array(12).fill(0)
            let total_bruto_salary = Array(12).fill(0)
            let total_incentive = Array(12).fill(0)
            let total_compensation = Array(12).fill(0)
            let total_provision = Array(12).fill(0)
            let total_tax = Array(12).fill(0)
            let total_trust_employer = Array(12).fill(0)
            let total_trust_employee = Array(12).fill(0)
            let total_bonus = Array(12).fill(0)

            res.data.data.forEach((elem) => {
              total_base_salary[elem._id - 1] = elem.total_base_salary
              total_salary_net[elem._id - 1] = elem.total_salary_net
              total_bruto_salary[elem._id - 1] = elem.total_bruto_salary
              total_incentive[elem._id - 1] = elem.total_incentive
              total_compensation[elem._id - 1] = elem.total_compensation
              total_provision[elem._id - 1] = elem.total_provision
              total_tax[elem._id - 1] = elem.total_tax
              total_trust_employer[elem._id - 1] = elem.total_trust_employer
              total_trust_employee[elem._id - 1] = elem.total_trust_employee
              total_bonus[elem._id - 1] = elem.total_bonus
            })

            this.setState({
              total_base_salary: total_base_salary,
              total_salary_net: total_salary_net,
              total_bruto_salary: total_bruto_salary,
              total_incentive: total_incentive,
              total_compensation: total_compensation,
              total_provision: total_provision,
              total_tax: total_tax,
              total_trust_employer: total_trust_employer,
              total_trust_employee: total_trust_employee,
              total_bonus: total_bonus
            })
          } else {
            this.setState({
              total_base_salary: [],
              total_salary_net: [],
              total_bruto_salary: [],
              total_incentive: [],
              total_compensation: [],
              total_provision: [],
              total_tax: [],
              total_trust_employer: [],
              total_trust_employee: [],
              total_bonus: []
            })
          }
        }
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({ error_message: 'Error fetching data from the server' })
      }
    }
  }

  _getBankForm() {
    const month = parseInt(moment(this.state.selectedDate).format('MM'))
    const year = parseInt(moment(this.state.selectedDate).format('YYYY'))
    var params = {
      month,
      year
    }
    axios
      .get('salaries/generateBankReport', { params, responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, 'BankForm.xlsx')
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  _getExport() {
    const month = parseInt(moment(this.state.selectedDate).format('MM'))
    const year = parseInt(moment(this.state.selectedDate).format('YYYY'))
    const body = {
      department: this.state.departmentSelected.length !== 0 ? this.state.departmentSelected : []
    }
    var params = {}
    if (!this.state.allExport) {
      params.month = month
    }
    if (!this.state.allExport) {
      params.year = year
    }
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke gjeneruar dokumentin!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    axios
      .post('salaries/export', body, { params, responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, `Pagat_${albanianMonths[params?.month - 1]}_${params?.year}.csv`)
          Swal.close()
          Swal.fire({
            title: 'Te dhenat u shkarkuan me sukses.',
            html: 'Dukumenti eshte gjeneruar me sukses'
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të Dokumentit',
          html: 'Gabim gjatë shkarkimit të Dokumentit'
        })
      })
  }

  _exportSalary = (salaryId, first_name, last_name, month, year) => {
    if (new Date(`${year}-${month}-01`) < new Date('2023-04-01')) {
      return Swal.fire({
        title: 'Kjo pagë për momentin nuk mund të shkarkohet!'
      })
    }
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke gjeneruar dokumentin për përdoruesin e zgjedhur!',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    axios
      .post(`salaries/${salaryId}`, null, { responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
          saveAs(response.data, `Paga_${first_name}_${last_name}_${albanianMonths[month - 1]}_${year}.pdf`)
        }
        Swal.close()
        Swal.fire({
          title: 'Te dhenat u shkarkuan me sukses.',
          html: 'Dukumenti eshte gjeneruar me sukses'
        })
      })
      .catch((error) => {
        console.log('error', error)
        pubsub.publish('showNotification', {
          message: 'Gabim gjatë shkarkimit të file',
          type: 'error'
        })
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të Dokumentit',
          html: 'Gabim gjatë shkarkimit të Dokumentit'
        })
      })
  }

  _getDataForTable = async (event) => {
    if (this._isMounted) {
      this.setState({ isLoading: true })
    }

    const {
      page,
      count,
      searchText,
      sortParam,
      sortType,
      projectSelected,
      departmentSelected,
      activitySelected,
      functionSelected,
      languageSelected
    } = this.state
    const month = parseInt(moment(this.state.selectedDate).format('MM'))
    const year = parseInt(moment(this.state.selectedDate).format('YYYY'))
    var params = {}
    params.month = month
    params.year = year
    params.search = searchText ? searchText : undefined
    params.sortParam = sortParam ? sortParam : undefined
    params.sortType = sortType ? sortType : undefined
    params['function'] = functionSelected ? functionSelected : undefined
    params['project'] = projectSelected ? projectSelected : undefined
    params['department[]'] = departmentSelected ? departmentSelected : undefined
    params['languages[]'] = languageSelected ? languageSelected : undefined
    params['activity'] = activitySelected ? activitySelected : undefined
    if (event) {
      if (this._isMounted) {
        await this.setState({
          searchText: event.target.value
        })
      }

      params.search = this.state.searchText
      window.onkeyup = (e) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          axios
            .get(`salaries/${count}/${page}`, { params })
            .then((res) => {
              if (res.data.success) {
                if (this._isMounted) {
                  this.setState({
                    getData: res.data.data.docs,
                    total: res.data.data.totalDocs,
                    isLoading: false
                  })
                }
              }
            })
            .catch((error) => {
              console.log('error', error)
            })
        }, 500)
      }
    } else {
      axios
        .get(`salaries/${count}/${page}`, { params })
        .then((res) => {
          if (res.data.success) {
            if (this._isMounted) {
              this.setState({
                getData: res.data.data.docs,
                total: res.data.data.totalDocs,
                isLoading: false
              })
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }

  async _callFilters() {
    try {
      const departments = await axios.get('departments')
      const activities = await axios.get('activities')
      const projects = await axios.get('projects')
      const languages = await axios.get('languages')
      const funksionet = await axios.get('roles')

      if (this._isMounted) {
        this.setState({
          departments: departments.data.success ? departments.data.data : undefined,
          activities: activities.data.success ? activities.data.data : undefined,
          projects: projects.data.success ? projects.data.data : undefined,
          languages: languages.data.success ? languages.data.data : undefined,
          funksionet: funksionet.data.success ? funksionet.data.data : undefined
        })
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  async componentDidMount() {
    this._isMounted = true
    await this._dateFunctionFiltered()
    await this._getDataForTable()
    await this._callFilters()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  async _onDateChange(date) {
    if (typeof date == 'object') {
      await this.setState({ selectedDate: date })
      await this._dateFunctionFiltered()
      await this._getDataForTable()
    }
  }

  _onDateBlur = (date) => {
    this.setState({ selectedDate: moment(date, 'YYYY-MM') })
  }

  onSortChange(param, type, fn) {
    this.setState({ sortParam: param, sortType: type }, fn)
  }
  render() {
    const level = this.props.userStore.userLevel
    const { count, total } = this.state
    const columns = [
      {
        name: 'Përdoruesi',
        label: 'Përdoruesi',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Projekti',
        label: 'Projekti',
        options: {
          sort: true,
          filter: true,
          display: this.state.showProject,
          filterList: this.state.projectRender,
          filterOptions: {
            names: this.state.projects.map((element) => element.name)
          }
        }
      },
      {
        name: 'Departamenti',
        label: 'Departamenti',
        options: {
          filterType: 'checkbox',
          sort: true,
          filter: true,
          filterList: this.state.departmentRender,
          filterOptions: {
            names: this.state.departments.map((element) => element.name)
          }
        }
      },
      {
        name: 'Aktiviteti',
        label: 'Aktiviteti',
        options: {
          sort: true,
          filter: true,
          display: this.state.showActivity,
          filterList: this.state.activityRender,
          filterOptions: {
            names: this.state.activities.map((element) => element.name)
          }
        }
      },
      {
        name: 'Pozita',
        label: 'Pozita',
        options: {
          sort: true,
          filter: true,
          display: this.state.showFunction,
          filterList: this.state.functionRender,
          filterOptions: {
            names: this.state.funksionet.map((element) => element.name)
          }
        }
      },
      {
        name: 'Gjuhet',
        label: 'Gjuhet',
        options: {
          sort: false,
          filter: true,
          display: this.state.showLanguages,
          filterList: this.state.languageRender,
          filterOptions: {
            names: this.state.languages.map((element) => element.code)
          }
        }
      },
      {
        name: 'Emaili',
        label: 'Emaili',
        options: {
          sort: true,
          filter: false,
          display: this.state.showEmail
        }
      },
      {
        name: 'Muaji',
        label: 'Muaji',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Viti',
        label: 'Viti',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Paga në bazë të vijueshmerisë',
        label: 'Paga në bazë të vijueshmerisë',
        options: {
          sort: true,
          filter: false
        }
      },
      // {
      //   name: "Provizioni",
      //   label: "Provizioni",
      //   options: {
      //     sort: true,
      //     filter: false,
      //   }
      // },
      {
        name: 'Incentiva',
        label: 'Incentiva',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Kompenzimi',
        label: 'Kompenzimi',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Paga Bruto',
        label: 'Paga Bruto',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Paga Neto',
        label: 'Paga Neto',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Trusti Punëdhënesi',
        label: 'Trusti Punëdhënesi',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Trusti Punëtori',
        label: 'Trusti Punëtori',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Bonusi',
        label: 'Bonus',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Orë pune',
        label: 'Orë pune',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Taksat',
        label: 'Taksat',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Data e procesimit',
        label: 'Data e procesimit',
        options: {
          sort: true,
          filter: false
        }
      },
      {
        name: 'Shkarko',
        label: 'Shkarko',
        options: {
          sort: false,
          filter: false
        }
      }
    ]
    const options = {
      elevation: 0,
      download: false,
      responsive: 'scroll',
      filterType: 'dropdown',
      selectableRows: 'none',
      pagination: true,
      search: false,
      viewColumns: true,
      serverSide: true,
      rowsPerPage: count,
      page: this.state.page - 1,
      count: total,
      rowsPerPageOptions: [10, 50, 100, 200],
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        },
        pagination: {
          next: 'Faqja tjeter',
          previous: 'Faqja e meparshme',
          rowsPerPage: 'Rreshta për faqe',
          displayRows: 'të'
        },
        toolbar: {
          search: 'Kërko',
          downloadCsv: 'Shkarko në CSV',
          print: 'Printo',
          viewColumns: 'Shiko kolonat',
          filterTable: 'Tabela e filtrimit'
        },
        filter: {
          all: 'TE GJITHA',
          title: 'FILTRIMET',
          reset: 'RIVENDOS'
        },
        viewColumns: {
          title: 'Shfaq kolonat',
          titleAria: 'Trego/fsheh kolonat e tabelave'
        }
      },

      onFilterChange: async (changedColumn, filterList) => {
        switch (changedColumn) {
          case 'Gjuhet':
            await this.setState({ languageRender: filterList[5] })

            this.state.languages.forEach((element) => {
              if (element.code == this.state.languageRender[0]) {
                this.setState({ languageSelected: element._id })
              } else if (this.state.languageRender.length == 0) {
                this.setState({ languageSelected: '' })
              }
            })

            this._getDataForTable()
            break
          case 'Pozita':
            await this.setState({ functionRender: filterList[4] })

            this.state.funksionet.forEach((element) => {
              if (element.name == this.state.functionRender[0]) {
                this.setState({ functionSelected: element._id })
              } else if (this.state.functionRender.length == 0) {
                this.setState({ functionSelected: '' })
              }
            })

            this._getDataForTable()
            break
          case 'Projekti':
            await this.setState({ projectRender: filterList[1] })

            this.state.projects.forEach((element) => {
              if (element.name == this.state.projectRender[0]) {
                this.setState({ projectSelected: element._id })
              } else if (this.state.projectRender.length == 0) {
                this.setState({ projectSelected: '' })
              }
            })

            this._getDataForTable()
            break
          case 'Departamenti':
            var array = []
            await this.setState({ departmentRender: filterList[2] })
            this.state.departments.map((department) => {
              filterList[2].forEach(async (element) => {
                if (department.name == element) {
                  array.push(department._id)
                  await this.setState({
                    departmentSelected: array
                  })
                }
              })
            })

            if (filterList[2].length == 0) {
              await this.setState({
                departmentSelected: ''
              })
            }

            this._getDataForTable()
            break
          case 'Aktiviteti':
            await this.setState({ activityRender: filterList[3] })

            this.state.activities.forEach((element) => {
              if (element.name == this.state.activityRender[0]) {
                this.setState({ activitySelected: element._id })
              } else if (this.state.activityRender.length == 0) {
                this.setState({ activitySelected: '' })
              }
            })

            this._getDataForTable()
            break
        }
      },
      onColumnSortChange: (changedColumn) => {
        const {
          monthType,
          yearType,
          baseSalaryType,
          incentiveType,
          compensationType,
          provisiontype,
          brutoSalaryType,
          salaryNetType,
          trustType,
          taxType,
          createdDateType,
          userFirstNameType,
          userEmailType,
          projectType,
          functionType,
          activityType
        } = this.state

        switch (changedColumn) {
          case 'Përdoruesi':
            this.setState(
              { userFirstNameType: userFirstNameType == -1 ? 1 : -1 },
              this.onSortChange('user.first_name', userFirstNameType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Emaili':
            this.setState(
              { userEmailType: userEmailType == -1 ? 1 : -1 },
              this.onSortChange('user.email', userEmailType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Muaji':
            this.setState(
              { monthType: monthType == -1 ? 1 : -1 },
              this.onSortChange('month', monthType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Viti':
            this.setState({ yearType: yearType == -1 ? 1 : -1 }, this.onSortChange('year', yearType == -1 ? 1 : -1, this._getDataForTable))
            break
          case 'Paga bazë':
            this.setState(
              { baseSalaryType: baseSalaryType == -1 ? 1 : -1 },
              this.onSortChange('base_salary', baseSalaryType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          // case 'Provizioni':
          //   this.setState({ provisiontype: provisiontype == -1 ? 1 : -1 }, this.onSortChange('provision', provisiontype == -1 ? 1 : -1, this._getDataForTable))
          //   break;
          case 'Incentiva':
            this.setState(
              { incentiveType: incentiveType == -1 ? 1 : -1 },
              this.onSortChange('incentive', incentiveType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Kompenzimi':
            this.setState(
              { compensationType: compensationType == -1 ? 1 : -1 },
              this.onSortChange('compensation', compensationType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Paga Bruto':
            this.setState(
              { brutoSalaryType: brutoSalaryType == -1 ? 1 : -1 },
              this.onSortChange('bruto_salary', brutoSalaryType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Paga Neto':
            this.setState(
              { salaryNetType: salaryNetType == -1 ? 1 : -1 },
              this.onSortChange('salary_net', salaryNetType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Trusti':
            this.setState(
              { trustType: trustType == -1 ? 1 : -1 },
              this.onSortChange('trust', trustType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Taksat':
            this.setState({ taxType: taxType == -1 ? 1 : -1 }, this.onSortChange('tax', taxType == -1 ? 1 : -1, this._getDataForTable))
            break
          case 'Data e procesimit':
            this.setState(
              { createdDateType: createdDateType == -1 ? 1 : -1 },
              this.onSortChange('created_date', createdDateType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Projekti':
            this.setState(
              { projectType: projectType == -1 ? 1 : -1 },
              this.onSortChange('user.project.name', projectType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Aktiviteti':
            this.setState(
              { activityType: activityType == -1 ? 1 : -1 },
              this.onSortChange('user.activity.name', activityType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
          case 'Pozita':
            this.setState(
              { functionType: functionType == -1 ? 1 : -1 },
              this.onSortChange('user.function.name', functionType == -1 ? 1 : -1, this._getDataForTable)
            )
            break
        }
      },
      customToolbar: () => {
        return (
          <fieldset>
            <FormGroup row className='justify-content-end '>
              <div className='col-md-10'>
                <label className='checkbox c-checkbox align-items-center'>
                  <Input
                    id='roundedcheckbox10'
                    type='checkbox'
                    onChange={this._allExport}
                    value={this.state.allExport}
                    checked={this.state.allExport}
                  />
                  <span className='fa fa-check' />
                  Eksporto te gjitha
                </label>
                <Tooltip title={'Shkarko CSV'}>
                  <IconButton
                    aria-label={'Shkarko CSV'}
                    onClick={() => {
                      this._getExport()
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Shkarko formën e Bankës'}>
                  <IconButton
                    aria-label={'Shkarko formën e Bankës'}
                    onClick={() => {
                      this._getBankForm()
                    }}
                  >
                    <AccountBalanceIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </FormGroup>
          </fieldset>
        )
      },

      onTableChange: async (action, tableState) => {
        switch (action) {
          case 'changePage':
            await this.setState({ page: tableState.page + 1 })
            this._getDataForTable()
            break
          case 'changeRowsPerPage':
            await this.setState({ count: tableState.rowsPerPage })
            this._getDataForTable()
            break
          case 'columnViewChange':
            this.setState({
              showProject: tableState.columns[1].display == 'true' ? true : false,
              showDepartment: tableState.columns[2].display == 'true' ? true : false,
              showActivity: tableState.columns[3].display == 'true' ? true : false,
              showFunction: tableState.columns[4].display == 'true' ? true : false,
              showLanguages: tableState.columns[5].display == 'true' ? true : false,
              showEmail: tableState.columns[6].display == 'true' ? true : false
            })
            break
          case 'resetFilters':
            this.setState({
              departmentRender: '',
              departmentSelected: '',
              projectRender: '',
              projectSelected: '',
              activityRender: '',
              activitySelected: '',
              functionRender: '',
              functionSelected: '',
              languageRender: '',
              languageSelected: ''
            })
        }
      }
    }
    const data = this.state.getData.map((elem) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
      return [
        <Link
          to={{
            pathname: `/wages/${elem.user._id}`,
            state: {
              userName: elem.user.first_name,
              lastName: elem.user.last_name
            }
          }}
        >
          {elem.user.first_name} {elem.user.last_name}
        </Link>,
        elem.user.project[0] && elem.user.project[0].name,
        elem.user.department[0] &&
          elem.user.department.map((object, index) => (elem.user.department.length - 1 == index ? object.name : object.name + ', ')),
        elem.user.activity[0] && elem.user.activity[0].name,
        elem.user.function[0] && elem.user.function[0].name,
        elem.user.languages[0] &&
          elem.user.languages.map((object, index) => (elem.user.languages.length - 1 == index ? object.code : object.code + ', ')),
        elem.user.email,
        albanianMonths[elem.month - 1],
        elem.year,
        formatter.format(elem.base_salary),
        // formatter.format(elem.provision),
        formatter.format(elem.incentive),
        formatter.format(isNaN(elem.compensation) ? 0.0 : elem.compensation),
        formatter.format(elem.bruto_salary),
        formatter.format(elem.salary_net),
        formatter.format(elem.trust_employer),
        formatter.format(elem.trust_employee),
        formatter.format(elem.bonus),
        elem.work_hours,
        formatter.format(elem.tax),
        moment(elem.created_date).format('DD/MM/YYYY'),
        <Button
          outline
          className='mb-1'
          color='warning'
          onClick={() => {
            this._exportSalary(elem._id, elem.user.first_name, elem.user.last_name, elem.month, elem.year)
          }}
          type='button'
        >
          <span>Shkarko</span>
        </Button>
      ]
    })

    const Line = {
      data: {
        labels: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
        datasets: [
          {
            label: 'Totali Paga Bazë',
            backgroundColor: 'rgba(14,52,64,0.2)',
            borderColor: 'rgba(14,52,64,1)',
            pointBorderColor: '#fff',
            data: this.state.total_base_salary
          },
          {
            label: 'Totali Paga Bruto',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: this.state.total_bruto_salary
          },
          {
            label: 'Totali Paga Neto',
            backgroundColor: 'rgba(35,183,229,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: this.state.total_salary_net
          },
          {
            label: 'Totali Incentiv',
            backgroundColor: 'rgba(255,0,255 ,0.2)',
            borderColor: 'rgba(255,0,255 ,1)',
            pointBorderColor: '#fff',
            data: this.state.total_incentive
          },
          {
            label: 'Totali Kompenzim',
            backgroundColor: 'rgba(80,50,50 ,0.2)',
            borderColor: 'rgba(80,50,50,1 )',
            pointBorderColor: '#fff',
            data: this.state.total_compensation
          },
          // {
          //   label: 'Totali provizion',
          //   backgroundColor: 'rgba(255,127,80 ,0.2)',
          //   borderColor: 'rgba(255,127,80 ,1)',
          //   pointBorderColor: '#fff',
          //   data: this.state.total_provision
          // },
          {
            label: 'Totali tax',
            backgroundColor: 'rgba(255,215,0,0.2)',
            borderColor: 'rgba(255,215,0,1)',
            pointBorderColor: '#fff',
            data: this.state.total_tax
          },
          {
            label: 'Totali trust punëdhënësi',
            backgroundColor: 'rgba(46,139,87,0.2)',
            borderColor: 'rgba(46,139,87,1)',
            pointBorderColor: '#fff',
            data: this.state.total_trust_employer
          },
          {
            label: 'Totali trust punëtori',
            backgroundColor: 'rgba(13, 204, 97,0.2)',
            borderColor: 'rgba(13, 204, 97,1)',
            pointBorderColor: '#fff',
            data: this.state.total_trust_employee
          },
          {
            label: 'Totali bonus',
            backgroundColor: 'rgba(255, 137, 15,0.2)',
            borderColor: 'rgba(255, 137, 15,1)',
            pointBorderColor: '#fff',
            data: this.state.total_bonus
          }
        ]
      },
      options: {
        legend: {
          display: true
        }
      }
    }
    return (
      <ContentWrapper>
        <div className='content-heading'>Menaxho Pagat</div>
        <Row>
          <Col lg={12} style={{ overflow: 'scroll' }}>
            {/* START panel tab */}
            <div className='card card-transparent'>
              <Nav tabs justified>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === 'myWages' ? 'active' : ''}
                    onClick={() => {
                      this.toggleTab('myWages')
                    }}
                  >
                    Pagat e muajit
                  </NavLink>
                </NavItem>
                {level == 1 && (
                  <>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === 'import' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('import')
                        }}
                      >
                        Importo paga
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </Nav>
              <TabContent activeTab={this.state.activeTab} className='bg-white p-0'>
                <TabPane tabId='myWages'>
                  {this.props.userStore.userLevel == 1 && (
                    <Row style={{ padding: 20 }}>
                      <Col>
                        <Row>
                          <Col>
                            <div className='form-group row mb float-left pl-3'>
                              <label className='col-form-label mb'>Zgjedh muajin:</label>
                              <Col md={5}>
                                <DateTimeBoostrap
                                  key='selectDate'
                                  value={this.state.selectedDate}
                                  onChange={this._onDateChange}
                                  locale='de'
                                  utc={true}
                                  closeOnSelect
                                  dateFormat='MM-YYYY'
                                  initialViewMode='years'
                                  onBlur={this._onDateBlur}
                                  data-validate='["validDate"]'
                                  timeFormat={false}
                                  inputProps={{
                                    'data-validate': '["validDate"]',
                                    className: 'form-control',
                                    name: 'data',
                                    autoComplete: 'off'
                                  }}
                                />
                              </Col>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ overflow: 'auto' }}>
                          <Card
                            body
                            style={{
                              width: this.state.innerWidth > 1024 ? this.state.innerWidth - 400 : 800
                            }}
                          >
                            <LineChart data={Line.data} options={Line.options} width={800} height={250} />
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className={this.state.isLoading ? 'whirl standard' : null} style={{ padding: 20 }}>
                    <Col>
                      <Card className={`shadow-none bg-white manageWagesTable`}>
                        <CardBody>
                          <div className='input-group'>
                            <input
                              name='searchBox'
                              value={this.state.searchText}
                              onChange={this._getDataForTable}
                              className='form-control'
                              type='text'
                              placeholder='Kerko perdoruesit...'
                            />
                            <span className='input-group-btn'>
                              <button disabled className='btn btn-secondary' type='button'>
                                <em className='fa fa-search' />
                              </button>
                            </span>
                          </div>
                        </CardBody>
                        <MUIDataTable
                          key={Math.random()}
                          ref={(ref) => (this.tableRef = ref)}
                          title={'Pagat'}
                          columns={columns}
                          options={options}
                          data={data}
                        />
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='import'>
                  <ImportTab />
                </TabPane>
              </TabContent>
            </div>
            {/* END panel tab */}
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default ManageWages
