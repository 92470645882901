import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import Locale from 'date-fns/locale/en-GB'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { office_location } from '../../../shared/enums/office_location'

const defaultFormValues = {
  position: '',
  requested: 1,
  language: '',
  department: '',
  location: '',
  request_type: '',
  due_date: null,
  creationComment: ''
}

const New_Staffing_Request_Modal = ({ getStaffingRequests, closeModal, languages }) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: defaultFormValues, mode: 'onTouched' })
  const [departments, setDepartments] = useState([])

  const onSubmit = async (data) => {
    axios
      .post('/staffingRequest', data)
      .then((res) => {
        if (res.data.success) {
          getStaffingRequests()
          toast.success('Kërkesa u krijua me sukses!')
          closeModal()
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Ndodhi një gabim!')
        console.log('error', err)
      })
  }

  const getAllDepartments = async () => {
    const res = await axios.get(`departments`)
    if (res.data.success) {
      setDepartments(res.data.data)
    } else {
      closeModal()
      toast.error('Ndodhi një gabim tek gjuhët!')
    }
  }

  useEffect(() => {
    getAllDepartments()
  }, [])

  return (
    <Dialog open={true} fullWidth sx={{ fieldset: { border: '1px solid' } }}>
      <DialogTitle>Shto një kërkesë të re për staf</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Controller
            name='position'
            control={control}
            defaultValue={defaultFormValues.position}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ width: '45%' }}
                margin='dense'
                label='Pozicioni'
                size='small'
                variant='outlined'
                error={Boolean(errors?.position)}
                helperText={errors?.position?.message || ''}
              />
            )}
            rules={{ required: 'Pozicioni është i domosdoshëm' }}
          />
          <Controller
            name='requested'
            control={control}
            defaultValue={defaultFormValues.requested}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ width: '45%' }}
                margin='dense'
                label='Te kerkuar'
                size='small'
                variant='outlined'
                type='number'
                error={Boolean(errors?.requested)}
                helperText={errors?.requested?.message || ' '}
                InputProps={{ inputProps: { min: 1 } }}
              />
            )}
            rules={{ required: 'Të kërkuar është e domosdoshme', min: { value: 1, message: 'Minimum value is 1' } }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Controller
            name='language'
            control={control}
            defaultValue={defaultFormValues.language}
            render={({ field }) => (
              <FormControl margin='dense' sx={{ width: '45%' }} error={Boolean(errors?.language)}>
                <InputLabel size='small' id='language'>
                  Gjuha
                </InputLabel>
                <Select {...field} size='small' labelId='language' input={<OutlinedInput label='Language' size='small' />}>
                  {languages?.map((lang) => (
                    <MenuItem key={lang._id} value={lang._id}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.language)}>{errors?.language?.message || ' '}</FormHelperText>
              </FormControl>
            )}
            rules={{ required: 'Gjuha është e domosdoshme' }}
          />
          <Controller
            name='department'
            control={control}
            defaultValue={defaultFormValues.department}
            render={({ field }) => (
              <FormControl margin='dense' sx={{ width: '45%' }} error={Boolean(errors?.department)}>
                <InputLabel size='small' id='department'>
                  Departamenti
                </InputLabel>
                <Select {...field} size='small' labelId='department' input={<OutlinedInput label='Department' size='small' />}>
                  {departments?.map((lang) => (
                    <MenuItem key={lang._id} value={lang._id}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.department)}>{errors?.department?.message || ' '}</FormHelperText>
              </FormControl>
            )}
            rules={{ required: 'Departamenti është i domosdoshëm' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Controller
            name='location'
            control={control}
            defaultValue={defaultFormValues.location}
            render={({ field }) => (
              <FormControl margin='dense' sx={{ width: '45%' }} error={Boolean(errors?.location)}>
                <InputLabel size='small' id='location'>
                  Lokacioni
                </InputLabel>
                <Select {...field} size='small' labelId='location' input={<OutlinedInput label='Location' size='small' />}>
                  {office_location?.map((location) => (
                    <MenuItem key={location.value} value={location.value}>
                      {location.value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.location)}>{errors?.location?.message || ' '}</FormHelperText>
              </FormControl>
            )}
            rules={{ required: 'Lokacioni është i domosdoshëm' }}
          />
          <Controller
            name='request_type'
            control={control}
            defaultValue={defaultFormValues.request_type}
            render={({ field }) => (
              <FormControl margin='dense' sx={{ width: '45%' }} error={Boolean(errors?.request_type)}>
                <InputLabel size='small' id='request_type'>
                  Lloji kerkeses
                </InputLabel>
                <Select {...field} size='small' labelId='request_type' input={<OutlinedInput label='Request Type' size='small' />}>
                  {['Backfill', 'Ramp-Up']?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.request_type)}>{errors?.request_type?.message || ' '}</FormHelperText>
              </FormControl>
            )}
            rules={{ required: 'Lloji i kërkesës e domosdoshëme' }}
          />
        </Box>
        <Controller
          name='due_date'
          control={control}
          defaultValue={defaultFormValues.due_date}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
              <DatePicker
                {...field}
                label='Data fundit'
                minDate={new Date()}
                inputFormat='dd/MM/yyyy'
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '45%' }}
                    {...params}
                    size='small'
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    helperText={errors?.due_date?.message || ' '}
                    error={Boolean(errors?.due_date)}
                  />
                )}
              />
            </LocalizationProvider>
          )}
          rules={{ required: 'Data e fundit është e domosdoshëme' }}
        />
        <Controller
          name='creationComment'
          control={control}
          defaultValue={defaultFormValues.creationComment}
          render={({ field }) => (
            <TextField {...field} margin='dense' label='Komenti' size='small' variant='outlined' fullWidth multiline maxRows={4} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} size='small' variant='contained' color='error'>
          Mbrapa
        </Button>
        <Button onClick={handleSubmit(onSubmit)} size='small' variant='contained'>
          Krijo
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default New_Staffing_Request_Modal
