import jwt_decode from 'jwt-decode'
import { getRoutes } from '../../getRoutes'

export const departmentIDs = {
  IT: '60dad7f65f871377f59e780d', // Pikotel
  Compliance: '6459ff3827e0949da9ebaa1a', // Compliance & Data Security
  Quality: '5d9b27f7d489d437f2950595', // Quality
  Sales: [
    '5d9b2813d489d437f2950597', // Sunrise - Telesales Inbound
    '5d9b2829d489d437f2950598', // Sunrise - Telesales Outbound
    '5d9b2758d489d437f295058e', // B2B Leadgen
    '60dac0275f871377f59e759d', // Management
    '669e46e2d95d316486b4fda2', // B2B Quality
    '669e46ced95d316486b4fc3b', // B2B Medium Sales
    '669e46b4d95d316486b4fa59', // B2B Small Sales
    '6464978027e0949da90482cc', // MIX Team

    //Maybe not used
    '60746327d80dab068a744657', // UPC SALES
    '5f57886559afbc06770e9a85', // Sunrise - Sales Outbound 2
    '602ce76ad80dab068a734588', // Sunrise - Sales Inbound 2
    '60a7a446a51abf1e2b1e6264' // ---DOESNT EXISTS
  ],
  MobileZone: ['65f1b308e4e23cf23d2c2cef', '6440e7c18422a203756af274', '64648a4a27e0949da904059e']
}

const checkDepartment = (department, ids) => ids.some((id) => department.includes(id))

export const getRoutesData = () => {
  const token = localStorage.getItem('token')
  const { user_level, department } = jwt_decode(token)

  return getRoutes({
    user_level,
    isITUser: checkDepartment(department, [departmentIDs.IT]),
    isComplianceUser: checkDepartment(department, [departmentIDs.Compliance]),
    isQualityUser: checkDepartment(department, [departmentIDs.Quality]),
    isSalesUser: checkDepartment(department, departmentIDs.Sales),
    isMobileZoneDepartment: checkDepartment(department, departmentIDs.MobileZone)
  })
}
